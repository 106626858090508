import { ErrorTracking } from "../error_tracking/error_tracking";
import {
  GetProposalResponse,
  RawProposalData,
  RawProposalFile,
} from "../types/proposal";
import Web from "../utils/networking";
import { API } from "./endpoints";

//TODO: Refactor this to make it more scalable
interface AuthResponseData {
  created: boolean;
  user_id: string;
  token: string;
}

interface AuthResult {
  success: boolean;
  created?: boolean;
  userId?: string;
  token?: string;
}

interface ProposalResponseData {
  proposals: RawProposalData[];
}

type AuthenticatedRequestProps = {
  token: string;
};

type GetProposalProps = {
  userId: string;
} & AuthenticatedRequestProps;

type CreateProposalProps = {
  creatorName: string;
  proposalName: string;
  description: string;
  metadata?: { key: string; value: string }[];
} & AuthenticatedRequestProps;

type CreateProposalResponse = {
  proposal_uid: string;
};

type CreateProposalData = {
  proposalUid: string;
};

export type GenericResponse<T = null> = {
  success: boolean;
  payload?: T;
  error?: string;
};

type AddFileProps = {
  formData: FormData;
} & AuthenticatedRequestProps;

type CommitProposalProps = {
  proposalUid: string;
} & AuthenticatedRequestProps;

type CommitProposalData = {
  proposalHash: string;
  metadataLocation: string;
};

type ConfirmProposalProps = {
  proposalUid: string;
  token: string;
  blockchainTransactionId: string;
  contractAddress: string;
};

type ProposalData = {
  proposals: RawProposalData[];
};

export type RecentProposal = {
  uid: string;
  name: string;
  blockchain_transaction_id: string;
  creator_name: string;
  date_created: string;
  token_id: string;
  description: string;
  thumbnail: string;
};

type GetRecentProposalsResponse = {
  proposals: RecentProposal[];
};

type GetProposalByIdResponse = {
  proposal: RawProposalData;
};

class Backend {
  static async authenticate(
    publicKey: string,
    message: string,
    signature: string
  ): Promise<AuthResult> {
    const url = API.users.authenticate();

    return Web.post(url, {
      public_key: publicKey,
      message: message,
      signature: signature,
    })
      .then((data: AuthResponseData) => {
        return {
          success: true,
          created: data.created,
          userId: data.user_id,
          token: data.token,
        };
      })
      .catch((error) => {
        ErrorTracking.captureError("authenticate");
        ErrorTracking.errorBreadcrumb(`${error}`);
        return {
          success: false,
        };
      });
  }

  static async getProposalsByUser({
    token,
    userId,
  }: GetProposalProps): Promise<GenericResponse<ProposalData>> {
    const headers = {
      Authorization: `Token ${token}`,
    };
    const url = API.proposals.list();

    return Web.get(url, { user_uid: userId }, headers)
      .then((data: ProposalResponseData) => {
        return {
          success: true,
          payload: {
            proposals: data.proposals,
          },
        };
      })
      .catch((error) => {
        ErrorTracking.captureError("getProposalsByUser");
        ErrorTracking.errorBreadcrumb(`${error}`);
        return {
          success: false,
          error: "Failed to fetch proposals",
        };
      });
  }

  static async createProposal({
    creatorName,
    proposalName,
    description,
    metadata,
    token,
  }: CreateProposalProps): Promise<GenericResponse<CreateProposalData>> {
    const headers = {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    };
    const url = API.proposals.create();

    return Web.post(
      url,
      {
        name: proposalName,
        description,
        creator_name: creatorName,
        custom_metadata: metadata,
      },
      headers
    )
      .then((response: CreateProposalResponse) => {
        return {
          success: true,
          payload: {
            proposalUid: response.proposal_uid,
          },
        };
      })
      .catch((error) => {
        ErrorTracking.captureError("createProposal");
        ErrorTracking.errorBreadcrumb(`${error}`);
        return {
          success: false,
          error: "Failed to create proposal",
        };
      });
  }

  static async addFileToProposal({
    formData,
    token,
  }: AddFileProps): Promise<GenericResponse> {
    const headers = {
      Authorization: `Token ${token}`,
    };
    const url = API.proposals.attachFile();

    return Web.post(url, formData, headers)
      .then((response: CreateProposalResponse) => {
        return {
          success: true,
        };
      })
      .catch((error) => {
        ErrorTracking.captureError("addFileToProposal");
        ErrorTracking.errorBreadcrumb(`${error}`);
        return {
          success: false,
          error: "Failed to add file proposal",
        };
      });
  }

  static async commitProposal({
    proposalUid,
    token,
  }: CommitProposalProps): Promise<GenericResponse<CommitProposalData>> {
    const headers = { Authorization: `Token ${token}` };
    const url = API.proposals.commit();

    return Web.patch(
      url,
      {
        proposal_uid: proposalUid,
      },
      headers
    )
      .then((response) => {
        return {
          success: true,
          payload: {
            proposalHash: response.proposal_hash,
            metadataLocation: response.metadata_location,
          },
        };
      })
      .catch((error) => {
        ErrorTracking.captureError("commitProposal");
        ErrorTracking.errorBreadcrumb(`${error}`);
        return {
          success: false,
          error: "Failed to commit proposal to the blockchain",
        };
      });
  }

  static confirmProposal = ({
    proposalUid,
    token,
    blockchainTransactionId,
    contractAddress,
  }: ConfirmProposalProps): Promise<GenericResponse> => {
    const headers = {
      Authorization: `Token ${token}`,
    };
    const url = API.proposals.confirm();

    return Web.patch(
      url,
      {
        proposal_uid: proposalUid,
        blockchain_transaction_id: blockchainTransactionId,
        contract_address: contractAddress,
      },
      headers
    )
      .then(() => {
        return {
          success: true,
        };
      })
      .catch((error) => {
        ErrorTracking.captureError("confirmProposal");
        ErrorTracking.errorBreadcrumb(`${error}`);
        return {
          success: false,
          error: "Failed to confirm proposal",
        };
      });
  };

  static async getRecentProposals(): Promise<
    GenericResponse<GetRecentProposalsResponse>
  > {
    try {
      const url = API.proposals.recent();
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (!response.ok) {
        return {
          success: false,
          error: data.message || "Failed to fetch recent proposals",
        };
      }

      return {
        success: true,
        payload: { proposals: data },
      };
    } catch (error) {
      return {
        success: false,
        error: "An error occurred while fetching recent proposals",
      };
    }
  }

  static async getProposalById(
    uid: string
  ): Promise<GenericResponse<RawProposalData>> {
    try {
      const response = await fetch(API.proposals.getById(uid), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (!response.ok) {
        return {
          success: false,
          error: data.message || "Failed to fetch proposal",
        };
      }

      return {
        success: true,
        payload: data,
      };
    } catch (error) {
      return {
        success: false,
        error: "An error occurred while fetching the proposal",
      };
    }
  }
}

export type { AuthResult, ProposalResponseData, RawProposalData };
export { Backend };
