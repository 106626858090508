export type RawArtifactsLocation = {
  decentralised_metadata_location: string;
  decentralised_zipfile_location: string;
  zipfile_location: string;
};

export enum ProposalStatus {
  Committed = "committed",
  Confirmed = "confirmed",
  Pending = "pending",
}

export type ProposalFile = {
  id: string;
  name: string;
  type: string;
  dateCreated: string;
  url?: string;
};

export type RawProposalFile = {
  uid: string;
  type: string;
  date_created: string;
  public_url: string;
  key: string;
  name: string;
};

// Raw data from API
export type RawProposalData = {
  uid: string;
  name: string;
  user_id: string;
  blockchain_transaction_id?: string;
  metadata: object;
  artifact_locations: RawArtifactsLocation;
  creator_name: string;
  description: string;
  date_created: string;
  hashed_contents?: string;
  files: RawProposalFile[];
  token_id?: string;
  status: string;
};

// Frontend-friendly version
export type Proposal = {
  id: string;
  name: string;
  creator: string;
  description?: string;
  blockchainTransactionId?: string;
  metadata?: object;
  files: ProposalFile[];
  dateCreated: string;
  hashedContents?: string;
  tokenId?: string;
  artifactLocations?: ArtifactsLocation;
  status: ProposalStatus;
};

export type ArtifactsLocation = {
  decentralisedMetadataLocation: string;
  decentralisedZipfileLocation: string;
  zipfileLocation: string;
};

// API Response types
export type GetProposalResponse = {
  proposal: RawProposalData;
};

export type GetRecentProposalsResponse = {
  proposals: Array<{
    uid: string;
    name: string;
    blockchain_transaction_id: string;
    creator_name: string;
    date_created: string;
  }>;
};

// Helper function to convert raw API data to frontend format
export const transformProposalData = (raw: RawProposalData): Proposal => ({
  id: raw.uid,
  name: raw.name,
  creator: raw.creator_name,
  description: raw.description,
  blockchainTransactionId: raw.blockchain_transaction_id,
  metadata: raw.metadata,
  dateCreated: raw.date_created,
  hashedContents: raw.hashed_contents,
  tokenId: raw.token_id,
  artifactLocations: {
    decentralisedMetadataLocation:
      raw.artifact_locations.decentralised_metadata_location,
    decentralisedZipfileLocation:
      raw.artifact_locations.decentralised_zipfile_location,
    zipfileLocation: raw.artifact_locations.zipfile_location,
  },
  status: mapProposalStatus(raw.status),
  files: raw.files.map((file) => {
    return {
      id: file.uid,
      name: file.name,
      type: file.type,
      dateCreated: file.date_created,
      url: file.public_url,
    };
  }),
});

const mapProposalStatus = (status: string): ProposalStatus => {
  if (status === "confirmed") return ProposalStatus.Confirmed;
  if (status === "committed") return ProposalStatus.Committed;
  if (status === "pending") return ProposalStatus.Pending;
  return ProposalStatus.Pending;
};
