import path from "path";

// Group related endpoints into namespaces
const ENDPOINTS = {
  users: {
    authenticate: "/users/auth",
  },
  proposals: {
    list: "/proposals/proposals-by-user",
    create: "/proposals/proposal",
    recent: "/proposals/summary",
    attachFile: "/proposals/file",
    commit: "/proposals/commit",
    confirm: "/proposals/confirm",
    getById: (uid: string) => `/proposals/${uid}`,
  },
} as const;

// Type-safe helper to create full URLs
export const createUrl = (endpoint: string) => {
  const BASE_URL = process.env.NEXT_PUBLIC_BACKEND_URL;
  return new URL(path.join(endpoint), BASE_URL).toString();
};

// Type-safe helpers for each endpoint
export const API = {
  users: {
    authenticate: () => createUrl(ENDPOINTS.users.authenticate),
  },
  proposals: {
    list: () => createUrl(ENDPOINTS.proposals.list),
    create: () => createUrl(ENDPOINTS.proposals.create),
    recent: () => createUrl(ENDPOINTS.proposals.recent),
    attachFile: () => createUrl(ENDPOINTS.proposals.attachFile),
    commit: () => createUrl(ENDPOINTS.proposals.commit),
    confirm: () => createUrl(ENDPOINTS.proposals.confirm),
    getById: (uid: string) => createUrl(ENDPOINTS.proposals.getById(uid)),
  },
} as const;
